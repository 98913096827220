.work__container {
  gap: 3rem;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
}

.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.work__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  padding: 1.25rem;
  border-radius: 1rem;
  transition: transform 0.2s ease-out;
  overflow: hidden;
}

.img__container {
  max-width: 295px;
  max-height: 175px;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  margin-left: auto;
  margin-right: auto;
}

.work__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.05);
  }
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__subtitle {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
  margin-bottom: var(--mb-1);
  display: inline-block;
  padding-left: 0.25rem;
}

.bx-info-circle {
  font-size: var(--normal-font-size);
  color: var(--first-color);
  display: inline-block;
  vertical-align: middle;
}

.work__button {
  width: max-content;
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

/* Active Item Work */
.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
}

.link_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*==================== MEDIA QUERIES ====================*/
/* For large devices */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.75rem;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work__img {
    width: 100%;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}
